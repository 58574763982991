import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { classes } from './signUp.styles';
import { signUp } from '../../../api/projectmyfinance.api';

export interface Credentials {
  email: string;
  password: string;
}

export function SignUp() {
  const [credentials, setCredentials] = React.useState<Credentials>({
    email: '',
    password: '',
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [event.target.id]: event.target.value,
    } as Pick<Credentials, keyof Credentials>);
  };

  function handleSubmit(event: React.ChangeEvent<HTMLFormElement>): void {
    event.preventDefault();
    signUp(credentials.email, credentials.password);
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={classes.paper}>
        <Avatar sx={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form noValidate onSubmit={handleSubmit}>
          <FormControl sx={classes.form}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid container item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button
              id="sign-up"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link
                  to="/sign-in"
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <Button id="sign-in" variant="contained" color="secondary">
                    Already have an account? Sign in!
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </Box>
    </Container>
  );
}
