import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Account,
  AccountBalance,
  AccountData,
  AppInfo,
  AppState,
  Card,
  CardBalance,
  CardData,
  CardTransaction,
  DirectDebit,
  ErrorState,
  PartnerUser,
  Settings,
  StandingOrder,
  Transaction,
  Truelayer,
  User
} from '../models/models';
import { loadState, saveState } from './localStorage';

export const initialState: AppState = {
  appInfo: {
    name: '',
    version: '',
  },
  blackout: false,
  isAuthenticated: false,
  authorization: '',
  darkMode: false,
  currentUser: {
    email: '',
    roles: [{ name: 'ROLE_USER' }],
  },
  settings: {
    id: '',
    birthDate: '',
    firstName: '',
    lastName: '',
    middleNames: '',
  },
  errorState: {
    enabled: false,
  },
  accountData: [],
  cardData: [],
  accountBalances: [],
  cardBalances: []
};

export const appSlice = createSlice({
  name: 'app',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAppInfo: (state, action: PayloadAction<AppInfo>) => {
      state.appInfo = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
      saveState({ ...loadState(), darkMode: action.payload });
    },
    setBlackoutMode: (state, action: PayloadAction<boolean>) => {
      state.blackout = action.payload;
      saveState({ ...loadState(), blackout: action.payload });
    },
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    setSettings: (state, action: PayloadAction<Settings>) => {
      state.settings = action.payload;
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      state.avatar = action.payload;
    },
    setPartnerAvatar: (
      state,
      action: PayloadAction<{ email: string; avatar: string }>
    ) => {
      const updatedPartners = state.partners?.map((partner: PartnerUser) => {
        if (partner.email === action.payload.email) {
          return {
            ...partner,
            avatar: action.payload.avatar,
          };
        }
        return partner;
      });

      return {
        ...state,
        partners: updatedPartners,
        loading: { loading: false }, // Assuming loading flag is part of your state
      };
    },

    setErrorState: (state, action: PayloadAction<ErrorState>) => {
      state.errorState = action.payload;
    },
    setTruelayer: (state, action: PayloadAction<Truelayer[]>) => {
      state.truelayer = action.payload;
    },
    setAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload;
    },
    setAccountData: (state, action: PayloadAction<AccountData>) => {
      const index = state.accountData.findIndex(account => account.account_id === action.payload.account_id);
      if (index !== -1) {
        // Update existing card
        state.accountData[index] = action.payload;
      } else {
        // Add new card
        state.accountData.push(action.payload);
      }
    },
    setAccountBalance: (state, action: PayloadAction<AccountBalance>) => {
      const index = state.accountBalances.findIndex(balance => balance.account_id === action.payload.account_id);

      if (index !== -1) {
        // If the balance exists, update it
        state.accountBalances[index] = action.payload;
      } else {
        // If the balance does not exist, add it
        state.accountBalances.push(action.payload);
      }
    },
    setAccountTransactions: (state, action: PayloadAction<Transaction[]>) => {
      state.transactions = action.payload;
    },
    setAllAccountTransactions: (state, action: PayloadAction<Transaction[]>) => {
      state.allAccountTransactions = action.payload;
    },
    setAccountPendingTransactions: (
      state,
      action: PayloadAction<CardTransaction[]>
    ) => {
      state.pendingCardTransactions = action.payload;
    },
    setAllCardTransactions: (
      state,
      action: PayloadAction<CardTransaction[]>
    ) => {
      state.allCardTransactions = action.payload;
    },
    setCardTransactions: (state, action: PayloadAction<CardTransaction[]>) => {
      state.cardTransactions = action.payload;
    },
    setCardPendingTransactions: (
      state,
      action: PayloadAction<CardTransaction[]>
    ) => {
      state.pendingCardTransactions = action.payload;
    },
    setAccountStandingOrders: (
      state,
      action: PayloadAction<StandingOrder[]>
    ) => {
      state.standingOrders = action.payload;
    },
    setAccountDirectDebits: (state, action: PayloadAction<DirectDebit[]>) => {
      state.directDebits = action.payload;
    },
    setCards: (state, action: PayloadAction<Card[]>) => {
      state.cards = action.payload;
    },
    setCardData: (state, action: PayloadAction<CardData>) => {
      const index = state.cardData.findIndex(card => card.account_id === action.payload.account_id);
      if (index !== -1) {
        // Update existing card
        state.cardData[index] = action.payload;
      } else {
        // Add new card
        state.cardData.push(action.payload);
      }
    },
    setCardBalance: (state, action: PayloadAction<CardBalance>) => {
      const index = state.cardBalances.findIndex(balance => balance.account_id === action.payload.account_id);

      if (index !== -1) {
        // If the balance exists, update it
        state.cardBalances[index] = action.payload;
      } else {
        // If the balance does not exist, add it
        state.cardBalances.push(action.payload);
      }
    },
    setPartnerAccounts: (
      state,
      action: PayloadAction<{ [email: string]: Account[] }>
    ) => {
      const updatedPartners = state.partners?.map((partner) => {
        const updatedAccounts = action.payload[partner.email];
        if (updatedAccounts && updatedAccounts.length > 0) {
          return { ...partner, accounts: updatedAccounts };
        }
        return partner;
      });

      if (updatedPartners) {
        state.partners = updatedPartners;
      }
    },
    setPartnerCards: (
      state,
      action: PayloadAction<{ [email: string]: Card[] }>
    ) => {
      const updatedPartners = state.partners?.map((partner) => {
        const updatedCards = action.payload[partner.email];
        if (updatedCards && updatedCards.length > 0) {
          return { ...partner, cards: updatedCards };
        }
        return partner;
      });

      if (updatedPartners) {
        state.partners = updatedPartners;
      }
    },
    setProviders: (state, action: PayloadAction<never[]>) => {
      state.providers = action.payload;
    },
    setPartners: (state, action: PayloadAction<PartnerUser[]>) => {
      state.partners = action.payload;
    },
    clearTransactions: (state) => {
      state.pendingTransactions = undefined;
      state.transactions = undefined;
      state.pendingCardTransactions = undefined;
      state.cardTransactions = undefined;
    },
    clearState:(state) => {
      state.accounts = undefined;
      state.cards = undefined;
      state.partners = undefined;
    }
  },
});
