import {
  CardHeader,
  Card as CardUI,
  Grid,
  Skeleton,
  Typography,
  Box
} from '@mui/material';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { getTruelayerCardBalance } from '../../api/projectmyfinance.api';
import { AppState, CardData, Provider } from '../../models/models';

const classes = {
  card: {
    border: 'transparent solid 1px',
    '&:hover': {
      border: '#000 solid 1px',
      cursor: 'pointer'
    },
    // Adjust padding for mobile
    padding: { xs: '4px', sm: '8px' },
    // Adjust font size for mobile
    fontSize: { xs: '0.75rem', sm: '1rem' }
  }
};

export interface CardCardProps {
  index: number;
  cardData?: CardData;
  provider?: Provider;
  openTransactions: (account_id: string) => void;
}

export function CardCard(props: CardCardProps) {
  const { cardData } = props;
  const cardBalances = useSelector((state: AppState) => state.cardBalances);
  const providers = useSelector((state: AppState) => state.providers);

  useEffect(() => {
    if (cardData) getTruelayerCardBalance(cardData.account_id)
  }, [cardData]);

  const title =
    cardData && cardData.display_name.length > 40
      ? `${cardData.display_name.slice(0, 37)}...`
      : cardData && cardData.display_name;

  return (
    <Grid item xs={12}>
      {cardData ?
        (<CardUI
            sx={classes.card}
            onClick={() => cardData && props.openTransactions(cardData.account_id)}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3} sm={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', maxWidth: "200px"  }}>
                  <img
                    src={providers && providers.find(provider => provider.provider_id === cardData.provider.provider_id)?.logo_url}
                    width={90} // Adjusted width for mobile
                    alt="Provider Logo"
                  />
                </Box>
              </Grid>
              <Grid item xs={8} sm={5}>
                <CardHeader
                  sx={{
                    textAlign: 'left',
                    padding: '0px' // Remove default padding for better spacing
                  }}
                  titleTypographyProps={{ variant: 'body1', noWrap: true }}
                  subheaderTypographyProps={{ variant: 'body2', noWrap: true }}
                  title={title}
                  subheader={cardData && `${cardData.card_type} | ${cardData.card_network} | ${cardData.partial_card_number}`}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <CardHeader
                  sx={{ textAlign: 'right', padding: '0px' }} // Remove default padding for better spacing
                  titleTypographyProps={{ variant: 'h6', noWrap: true }} // Smaller font size for mobile
                  subheaderTypographyProps={{ variant: 'body2', noWrap: true }} // Smaller font size for mobile
                  title={
                    <>
                      <Typography variant="h6">
                        <NumericFormat
                          id="pending-balance"
                          displayType="text"
                          prefix={"£"}
                          value={cardBalances?.find(balance => balance.account_id === cardData.account_id)?.current ?? 0}
                          thousandSeparator={true}
                          decimalScale={2}
                        />
                      </Typography>
                      <Typography variant="body2">
                        <span>Available: </span>
                        <NumericFormat
                          displayType="text"
                          prefix={"£"}
                          value={cardBalances?.find(balance => balance.account_id === cardData.account_id)?.available ?? 0}
                          thousandSeparator={true}
                          decimalScale={2}
                        />
                      </Typography>
                    </>
                  }
                />
              </Grid>
            </Grid>
          </CardUI>
        ) :
        <Skeleton variant="rectangular" height={90} sx={{ width: '100%', borderRadius: '4px' }} animation={'wave'} />}
    </Grid>
  );
}