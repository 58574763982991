import { Navigate } from 'react-router-dom';
export const Protected = ({
  isAuthenticated,
  children,
}: {
  isAuthenticated: boolean;
  children: JSX.Element;
}) => {
  if (!isAuthenticated) {
    return <Navigate to="/Sign-in" replace />;
  }
  return children;
};
