import { Box, Button, Modal } from '@mui/material';

interface ConfirmationProps {
  text: string;
  actionText: string;
  open: boolean;
  onClose: () => void;
  onAction: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  margin: '8px', // Adjust the margin as needed
};

export function Confirmation(props: ConfirmationProps) {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="confirmation"
      aria-describedby="confirmation-modal"
    >
      <Box sx={style}>
        <h2 id="confirmation">{props.text}</h2>
        <Button
          variant="contained"
          color="error"
          onClick={props.onAction}
          sx={buttonStyle} // Apply the button style here
        >
          {props.actionText}
        </Button>
        <Button
          variant="contained"
          onClick={props.onClose}
          sx={buttonStyle} // Apply the button style here
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
