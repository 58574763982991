import { Button, Card, CardContent, CardHeader, Container, Grid, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DatePicker, LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { classes } from './payments.styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import { NumericFormat } from 'react-number-format';
import { AppState } from '../../models/models';

export function Payments() {
  const directDebits = useSelector((state: AppState) => state.directDebits);
  const standingOrders = useSelector((state: AppState) => state.standingOrders);
  const [value, setValue] = useState<Date | null>(new Date());

  return (
    <Container maxWidth="xl" sx={classes.container}>
      <Grid container rowSpacing={3} columnSpacing={3} spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2} rowSpacing={2} direction={'column'}>
            <Grid item>
              <Card>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    views={['month', 'day']}
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={value}
                    showDaysOutsideCurrentMonth={true}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardHeader
                  sx={{ paddingBottom: 0 }}
                  titleTypographyProps={{ variant: 'h5' }}
                  subheaderTypographyProps={{ variant: 'body2' }}
                  title={'Create Payment'}
                ></CardHeader>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        placeholder="Description"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        placeholder="Value"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Basic example"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" marginTop={2}>
                    <Button variant="outlined">Submit</Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardHeader title="Payments"></CardHeader>
                <CardContent>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Last Payment Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody></TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2} rowSpacing={2} direction={'column'}>
            <Grid item>
              <Card>
                <CardHeader title="Direct Debits"></CardHeader>
                <CardContent>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {directDebits &&
                          directDebits
                            .filter(
                              (row) =>
                                row.status === 'Active' &&
                                row.previous_payment_amount !== 0
                            )
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    <NumericFormat
                                      displayType="text"
                                      value={row.previous_payment_amount}
                                      prefix={
                                        row.currency
                                          ? getSymbolFromCurrency(row.currency)
                                          : getSymbolFromCurrency('GBP')
                                      }
                                      thousandSeparator={true}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                  {new Date(
                                    row.previous_payment_timestamp
                                  ).toLocaleDateString()}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                    Cancelled
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {directDebits &&
                          directDebits
                            .filter(
                              (row) =>
                                row.status === 'Inactive' &&
                                row.previous_payment_amount !== 0
                            )
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">
                                    <NumericFormat
                                      displayType="text"
                                      value={row.previous_payment_amount}
                                      prefix={
                                        row.currency
                                          ? getSymbolFromCurrency(row.currency)
                                          : getSymbolFromCurrency('GBP')
                                      }
                                      thousandSeparator={true}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                  {new Date(
                                    row.previous_payment_timestamp
                                  ).toLocaleDateString()}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardHeader title="Standing Orders"></CardHeader>
                <CardContent>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {standingOrders &&
                          standingOrders.map((row) => (
                            <TableRow
                              key={row.reference}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.reference}
                              </TableCell>
                              <TableCell align="right">
                                  <NumericFormat
                                    displayType="text"
                                    value={row.next_payment_amount}
                                    prefix={
                                      row.currency
                                        ? getSymbolFromCurrency(row.currency)
                                        : getSymbolFromCurrency('GBP')
                                    }
                                    thousandSeparator={true}
                                  />
                              </TableCell>
                              <TableCell align="right">
                                {row.frequency}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
