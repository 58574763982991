import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { classes } from './credit.styles';
import { AppState } from '../../models/models';
import { LineChart } from '@mui/x-charts';
import { getTruelayerCardBalance } from '../../api/projectmyfinance.api';

export function Credit() {
  const allCardTransactions = useSelector((state: AppState) => state.allCardTransactions);
  const cards = useSelector((state: AppState) => state.cards);

  useEffect(() => {
    if (cards) {
      for (const card of cards) {
        getTruelayerCardBalance(card.accountId);
      }
    }
  }, [cards]);

  const totalCreditLimit = useSelector((state: AppState) =>
    state.cardBalances?.reduce((acc, card) => acc + (card.credit_limit || 0), 0) || 0
  );

  const aggregatedData = allCardTransactions
    ? allCardTransactions.reduce((acc, transaction) => {
      if (transaction && transaction.timestamp && transaction.running_balance?.amount != null) {
        const month = new Date(transaction.timestamp).toLocaleString('default', { month: 'short', year: 'numeric' });
        const amount = transaction.running_balance.amount;

        if (!acc[month] || new Date(transaction.timestamp) > new Date(acc[month].timestamp)) {
          acc[month] = {
            amount,
            timestamp: transaction.timestamp,
          };
        }
      }
      return acc;
    }, {} as Record<string, { amount: number; timestamp: string }>)
    : {};

  const months = Object.keys(aggregatedData).sort((a, b) => new Date(aggregatedData[a].timestamp).getTime() - new Date(aggregatedData[b].timestamp).getTime());
  const amounts = months.map(month => aggregatedData[month].amount);

  const seriesData = [
    {
      name: 'Running Balance',
      label: 'Running Balance',
      data: amounts,
      color: '#007fff',
    },
    {
      name: 'Credit Limit',
      label: 'Credit Limit',
      data: Array(months.length).fill(totalCreditLimit),
      color: 'red',
    },
  ];

  const hasData = amounts.length > 0;

  return (
    <Container maxWidth="xl" sx={classes.container}>
      <Grid container rowSpacing={3} columnSpacing={3} spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2} rowSpacing={2} direction={'column'}>
            <Grid item>
              {hasData ? (
                <LineChart
                  series={seriesData}
                  height={500}
                  xAxis={[{ data: months, scaleType: 'band' }]}
                  yAxis={[{ min: 0, max: totalCreditLimit }]}
                  margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                />
              ) : (
                <Typography variant="h6" color="textSecondary">
                  No transactions available to display.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
