import LockOutlined from '@mui/icons-material/LockOutlined';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  Link as StyledLink,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { classes } from './forgetPassword.styles';
import { forgotPassword } from '../../api/projectmyfinance.api';

export function ForgetPassword() {
  const [email, setEmail] = React.useState('');

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    forgotPassword(email);
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={classes.paper}>
        <Avatar sx={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgotten Your Password?
        </Typography>
        <FormControl
          sx={classes.form}
        >
          <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="username"
            label="Email"
            type="text"
            id="username"
            autoComplete="current-email"
            onChange={(email) => setEmail(email.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={classes.submit}
          >
            Send Email Reset
          </Button>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Link
                to="/"
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                <StyledLink href="#" variant="body2">
                  Back
                </StyledLink>
              </Link>
            </Grid>
          </Grid>
        </form>
        </FormControl>
      </Box>
    </Container>
  );
}
