import { configureStore } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { compose } from 'redux';
import { loadState } from './localStorage';
import { appSlice, initialState } from './reducer';

const accessToken = Cookies.get('accessToken');

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
  ? (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({
      trace: true,
      traceLimit: 25,
    }) as typeof compose)
  : compose;
const loadedState = loadState();
export const store = configureStore({
  reducer: appSlice.reducer,
  preloadedState: {
    ...initialState,
    ...loadedState,
    isAuthenticated: !!(accessToken && accessToken !== ''),
  },
  enhancers: composeEnhancers,
});
