import { AccountBalance, CardBalance } from '../models/models';

export function sumAccountBalances(accountBalances: AccountBalance[]): number {
    return accountBalances.reduce((total, balance) => {
        return total + (balance ? balance.current : 0);
    }, 0);
}

export function sumCardBalances(cardBalances: CardBalance[]): number {
    return cardBalances.reduce((total, balance) => {
        return total + (balance ? Math.abs(balance.current) : 0);
    }, 0);
}