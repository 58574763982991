import { createTheme } from '@mui/material';

const theme = createTheme();

export const classes = {
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  root: {
    minWidth: 275,
    marginBottom: 15,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
  title: {
    fontSize: 14,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  wrapper: {
    //padding: theme.spacing(2),
    overflow: 'auto',
    flexDirection: 'row',
  },
  closeableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bluePaper: {
    background:
      'linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 178) 120%)',
  },
  slideGrid: {
    height: 0,
  },
};
