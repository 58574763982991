import {
  Button,
  Container,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { PieChart, PieValueType } from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getTruelayerAccounts, getTruelayerCards } from '../../api/projectmyfinance.api';
import { AccountCard } from '../../components/account/accountCard';
import { CardCard } from '../../components/card/cardCard';
import { Account, AppState, Card } from '../../models/models';
import { sumAccountBalances, sumCardBalances } from '../../util/sumBalances';
import { classes } from './dashboard.styles';

export function Dashboard() {
  const navigate = useNavigate();
  const accounts = useSelector((state: AppState) => state.accounts);
  const accountData = useSelector((state: AppState) => state.accountData);
  const cards = useSelector((state: AppState) => state.cards);
  const cardData = useSelector((state: AppState) => state.cardData);
  const accountBalances = useSelector((state: AppState) => state.accountBalances);
  const cardBalances = useSelector((state: AppState) => state.cardBalances);
  const authLink = process.env.REACT_APP_AUTH_LINK;

  useEffect(() => {
    if (accounts)
      accounts.forEach(account => {
        getTruelayerAccounts(account.accountId);
      });
  }, [accounts]);

  useEffect(() => {
    if (cards)
      cards.forEach(card => {
        getTruelayerCards(card.accountId)
      });
  }, [cards]);

  return (
      <Container maxWidth="xl" sx={classes.container}>
        <Grid container rowSpacing={2} spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={12} md={12} lg={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <PieChart
                width={300}
                height={300}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: {
                      horizontal: "middle",
                      vertical: "bottom"
                    }
                  }
                }}
                series={[
                  {
                    data: [
                      { id: 0, value: accounts ? sumAccountBalances(accountBalances) : 0, label: 'Accounts' },
                      { id: 1, value: cards ? sumCardBalances(cardBalances) : 0, label: 'Credit Cards' },
                    ],
                    innerRadius: 66,
                    outerRadius: 100,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 180,
                    cx: 150,
                    cy: 150,
                    valueFormatter: (value?: MakeOptional<PieValueType, "id">) => {
                      if (value == null) {
                        return '';
                      }
                      return ` £${value.value.toLocaleString()}`;
                    }
                  }
                ]}
            />
          </Grid>
        </Grid>

        {/* Accounts Section */}
        <Box marginTop={4} marginBottom={2}>
          <Typography variant="h5" gutterBottom>
            Accounts
          </Typography>
          <Grid container rowSpacing={2} spacing={2}>
            {accounts && accounts.map((account: Account, i: number) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={`account-${i}`}>
                  <AccountCard
                      index={i}
                      accountData={accountData?.find(accountData => accountData.account_id === account.accountId)}
                      openTransactions={() => {
                        navigate(`/transactions/account/${account.accountId}`);
                      }}
                  />
                </Grid>
            ))}
          </Grid>
        </Box>

        {/* Cards Section */}
        <Box marginTop={4} marginBottom={2}>
          <Typography variant="h5" gutterBottom>
            Credit Cards
          </Typography>
          <Grid container rowSpacing={2} spacing={2}>
            {cards && cards.map((card: Card, i: number) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={`card-${i}`}>
                  <CardCard
                      index={i}
                      cardData={cardData?.find(cardData => cardData.account_id === card.accountId)}
                      openTransactions={() => {
                        navigate(`/transactions/card/${card.accountId}`);
                      }}
                  />
                </Grid>
            ))}
          </Grid>
        </Box>

        {/* Connect Your Bank Account Button */}
        {authLink && (
            <Grid container justifyContent="center" marginTop={2} marginBottom={2}>
              <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={authLink}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#125ea8',
                    },
                  }}
              >
                Connect Your Bank Account
              </Button>
            </Grid>
        )}
      </Container>
  );
}
