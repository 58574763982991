import CloseIcon from '@mui/icons-material/Close';
import { Alert, CssBaseline, IconButton, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  getAccounts,
  getAppInfo,
  getAvatar,
  getCards,
  getCurrentUser,
  getPartners,
  getProfileSettings,
  getProviders
} from './api/projectmyfinance.api';
import { Navbar } from './components/navbar/navbar';
import { Protected } from './components/protected/protected';
import { AppState } from './models/models';
import { ChangePassword } from './pages/changePassword/changePassword';
import { Credit } from './pages/credit/credit';
import { Dashboard } from './pages/dashboard/dashboard';
import { ForgetPassword } from './pages/forgetPassword/forgetPassword';
import { SignIn } from './pages/login/signIn/signIn';
import { SignUp } from './pages/login/signUp/signUp';
import { Payments } from './pages/payments/payments';
import { AccountSettings } from './pages/settings/settings';
import { Transactions } from './pages/transactions/transactions';
import { appSlice } from './store/reducer';
import { store } from './store/store';
import { darkTheme, theme } from './util/theme';

function App() {
  const location = useLocation();
  const state = useSelector((state: AppState) => state);

  useEffect(() => {
    store.dispatch(appSlice.actions.setErrorState({ enabled: false }));
  }, [location.pathname]);

  useEffect(() => {
    getAppInfo();
    if (state.isAuthenticated) {
      getCurrentUser();
      getProfileSettings();
      getAvatar();
      getAccounts();
      getCards();
      getProviders();
      getPartners();
    }
  }, [state.isAuthenticated]);

  return (
    <>
      {!state.isAuthenticated && state.errorState.enabled && (
        <Alert
          severity={state.errorState.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                store.dispatch(
                  appSlice.actions.setErrorState({ enabled: false })
                );
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {state.errorState.message}
        </Alert>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={state.darkMode ? darkTheme : theme}>
          <CssBaseline />
          {state.isAuthenticated && <Navbar /> }
            {state.isAuthenticated && state.errorState.enabled && (
              <Alert
                severity={state.errorState.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      store.dispatch(
                        appSlice.actions.setErrorState({ enabled: false })
                      );
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {state.errorState.message}
              </Alert>
            )}
            <Routes>
              <Route
                path={'/'}
                element={
                  <Protected isAuthenticated={state.isAuthenticated}>
                    <Dashboard />
                  </Protected>
                }
              />
              <Route
                path={'/transactions/:type/:id/:partner?'}
                element={
                  <Protected isAuthenticated={state.isAuthenticated}>
                    <Transactions />
                  </Protected>
                }
              />
              <Route path={'/sign-in'} element={<SignIn />} />
              <Route path={'/sign-up'} element={<SignUp />} />
              <Route
                path={'/accounts'}
                element={
                  <Protected isAuthenticated={state.isAuthenticated}>
                    <Payments />
                  </Protected>
                }
              />
              <Route
                path={'/credit'}
                element={
                  <Protected isAuthenticated={state.isAuthenticated}>
                    <Credit />
                  </Protected>
                }
                />
              <Route path={'/sign-in'} element={<SignIn />} />
              <Route path={'/sign-up'} element={<SignUp />} />
              <Route
                path={'/settings'}
                element={
                  <Protected isAuthenticated={state.isAuthenticated}>
                    <AccountSettings />
                  </Protected>
                }
              />
              <Route path={'/reset'} element={<ForgetPassword />} />
              <Route path={'/change-password'} element={<ChangePassword />} />
            </Routes>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
