import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { changePassword } from '../../api/projectmyfinance.api';
import { classes } from './changePassword.styles';

export interface PasswordForm {
  password: string;
  repeatPassword: string;
}

export function ChangePassword() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const [passwordForm, setPasswordForm] = React.useState<PasswordForm>({
    password: '',
    repeatPassword: '',
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordForm({
      ...passwordForm,
      [event.target.id]: event.target.value,
    } as Pick<PasswordForm, keyof PasswordForm>);
  };

  function handleSubmit(event: React.ChangeEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (email && token) {
      changePassword(
        email,
        token,
        passwordForm.password,
        passwordForm.repeatPassword
      );
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={classes.paper}>
        <Avatar sx={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <form noValidate onSubmit={handleSubmit}>
          <FormControl sx={classes.form}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid container item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="repeatPassword"
                  label="Repeat Password"
                  type="password"
                  id="repeatPassword"
                  autoComplete="current-password"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button
              id="sign-up"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={classes.submit}
            >
              Change Password
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link
                  to="/sign-in"
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <Button id="sign-in" variant="contained" color="secondary">
                    Remember? Sign in!
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </Box>
    </Container>
  );
}
