import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#007fff',
      contrastText: '#fff'
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    text: {
      primary: "#000",
      secondary: "#000",
    },
    mode: 'light',
  }, components: {
    MuiButton: {
      styleOverrides: {
        text: ({ color: 'white' })
      }
    }
  }
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#007fff',
      contrastText: '#fff'
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
    },
    mode: 'dark',
  }
});
