import { createTheme } from '@mui/material';
import { blue } from '@mui/material/colors';

const theme = createTheme();

export const classes = {
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 'auto',
    width: 'auto',
  },
  avatar: {
    marginTop: 5,
    width: 100,
    height: 100,
    marginBottom: 2,
  },
  avatarPartner: {
    margin: "auto",
    width: 50,
    height: 50,
    bgcolor: blue['500'],
  },
  button: {
    margin: theme.spacing(1),
  },
  ellipsis: {
    maxWidth: 100, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  input: {
    display: 'none',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  upload: {
    marginTop: 3,
  },
};
